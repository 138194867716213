* {
  margin: 0;
  padding: 0;
}
.table {
}
@media screen and (max-width: 1023px) {
  .table {
    overflow: auto;
    display: block !important;
    table-layout: auto !important;
  }
}
