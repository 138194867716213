.parts-container:last-child {
  font-size: 0.8rem !important;
}

.table-container {
  width: 65% !important;
}
/* .react-bootstrap-table table {
    min-width: 300px;
  }
   */
