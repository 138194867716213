.header_expanded {
  /* position: absolute; */
  width: 220px;
  transition: width 0.3s;
}

.header_collapsed {
  width: 80px;
  transition: width 0.3s;
}
#sidebar {
  position: relative;
}

#sidebar .pro-sidebar {
  height: 100vh;
}
#sidebar .toggleSidebar {
  position: absolute;
  right: -13px;
  z-index: 10000;
  line-height: 20px;
  font-size: 24px;
  border-radius: 50%;
  top: 50%;
  cursor: pointer;
}
#sidebar .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#sidebar .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#sidebar .pro-sidebar-inner {
  background-color: #889eaf;
  height: 100vh;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
  transition: width 0.3s;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: white;
  font-weight: bold;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: white;
  margin: 10px 0px;
  font-weight: bold;
}
#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #0cbaba;
  color: #fff;
  border-radius: 3px;
}
#sidebar
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#sidebar .pro-sidebar-inner .pro-sidebar-layout .active {
  /* background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%); */
  background-color: #2f86a6;
  background-image: linear-gradient(315deg, #0cbaba 0%, #380036 74%);
}
#sidebar .logo {
  padding: 20px;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #889eaf;
}

@media screen and (max-height: 800px) {
  #sidebar .pro-sidebar-inner {
    height: auto;
  }
}
